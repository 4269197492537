import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const LimbusGuidesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="lc">
      <ul className="breadcrumb">
        <li>
          <Link to="/limbus-company/">Limbus Company</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/limbuscompany/categories/category_3.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Guides</h1>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Newbie zone" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Introduction to the game"
            link="/limbus-company/guides/introduction-to-the-game"
            image={
              <StaticImage
                src="../../../images/limbuscompany/categories/category_intro.jpg"
                alt="Introduction to the game"
              />
            }
          />
          <CategoryCard
            title="Reroll"
            link="/limbus-company/guides/reroll"
            image={
              <StaticImage
                src="../../../images/limbuscompany/categories/category_reroll.jpg"
                alt="Reroll"
              />
            }
          />
          <CategoryCard
            title="Beginner guide"
            link="/limbus-company/guides/beginner-guide"
            image={
              <StaticImage
                src="../../../images/limbuscompany/categories/category_beg.jpg"
                alt="Beginner"
              />
            }
          />
          <CategoryCard
            title="Beginner teams"
            link="/limbus-company/guides/beginner-team-ideas"
            image={
              <StaticImage
                src="../../../images/limbuscompany/categories/category_teamb.png"
                alt="Beginner team ideas"
              />
            }
          />
          <CategoryCard
            title="Uptie 4 priority guide"
            link="/limbus-company/guides/uptie-priority"
            image={
              <StaticImage
                src="../../../images/limbuscompany/categories/category_uptie.png"
                alt="Uptie 4 priority guide"
              />
            }
          />
        </Row>
        <SectionHeader title="Generic" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Combat mechanics"
            link="/limbus-company/guides/combat-mechanics"
            image={
              <StaticImage
                src="../../../images/limbuscompany/categories/category_combat.jpg"
                alt="Combat mechanics"
              />
            }
          />
          <CategoryCard
            title="Combat mechanics (Advanced)"
            link="/limbus-company/guides/advanced-combat-mechanics"
            image={
              <StaticImage
                src="../../../images/limbuscompany/categories/category_advanced.webp"
                alt="Combat mechanics"
              />
            }
          />
          <CategoryCard
            title="Red Eyes vs W Corp Ryoshu"
            link="/limbus-company/guides/re-vs-w-corp-ryoshu"
            image={
              <StaticImage
                src="../../../images/limbuscompany/categories/category_ryoshu.webp"
                alt="Ryoshu vs Ryoshu"
              />
            }
          />
          <CategoryCard
            title="Gacha and monetization"
            link="/limbus-company/guides/gacha-and-monetization"
            image={
              <StaticImage
                src="../../../images/limbuscompany/categories/category_gacha.png"
                alt="Gacha and monetization"
              />
            }
          />
          {/* <CategoryCard
            title="Refraction Railway"
            link="/limbus-company/guides/refraction-railway"
            image={
              <StaticImage
                src="../../../images/limbuscompany/categories/category_rail.png"
                alt="Refraction Railway"
              />
            }
          /> */}
          {/* <CategoryCard
            title="Mirror Dungeon 2 Hard"
            link="/limbus-company/guides/mirror-hard"
            image={
              <StaticImage
                src="../../../images/limbuscompany/categories/category_mirror2.png"
                alt="Mirror Dungeon 2 Hard"
              />
            }
          /> */}
        </Row>
        <SectionHeader title="Lore" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Characters"
            link="/limbus-company/guides/characters-lore"
            image={
              <StaticImage
                src="../../../images/limbuscompany/categories/category_6.jpg"
                alt="Characters"
              />
            }
          />
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default LimbusGuidesPage;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Limbus Company | Prydwen Institute"
    description="Our guides for Limbus Company."
    game="limbus"
  />
);
